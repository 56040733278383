import '../App.css';
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import avatar1 from '../static/avatar1.png'
import {BiPhone} from "react-icons/bi"
import { CiMail } from "react-icons/ci";
import { FaGlobeAmericas, FaBriefcase} from "react-icons/fa";
import { RiTeamLine } from "react-icons/ri";
import {GiEgypt} from "react-icons/gi";


function Team(props){
    return(
        <div>
        <div className="heading" style={{marginBottom: '-3%'}}>
             Team         <RiTeamLine  style={{fontSize:'12rem'}}/>
        </div>
        <Row >
            <Col xs = {12} s = {12} md = {6} lg = {6}>
            <div className='subheading'> {props.en? "Co-Founder":"Mitbegründer"}</div>
            <div className='text'> {props.en?"Mr. ":"Herr "}Andrew Ayad</div>
            <div className='small-text'> <CiMail /> a.ayad@aqp-groups.com </div>
            </Col>
            <Col xs = {12} s = {12} md = {6} lg = {6}>
            <img src = {avatar1}  style ={{width:'30%', borderRadius:'50%', margin:'2% 0'}}/>
            </Col>
        </Row>
        <div style={{backgroundColor:'#EEEEEE'}}>
        <div className="heading" >
        {props.en?"Our Members in Egypt":"Unsere Mitglieder in Ägypten"}   <GiEgypt style={{fontSize:'12rem', padding:'1.5%'}}/>
        </div>
        <Row style={{padding:'3%'}}>
            <Col xs = {12} s = {12} md = {4} lg = {4} style={{padding:'3%'}}>
            <div className='subheading'> {props.en?"Sales Manager ":"Verkaufleitung"}</div>
            <div className='text'> {props.en?"Mr. ":"Herr "}. Ayman Antar</div>
            <div className='small-text'><CiMail /> a.antar@aqp-groups.com </div>
            </Col>
            <Col xs = {12} s = {12} md = {4} lg = {4} style={{padding:'3%'}}>
            <div className='subheading'> {props.en?"Operation Manager":"Betriebsleitung"}</div>
            <div className='text'>{props.en?"Mr. ":"Herr "}Tamer Farag</div>
            <div className='small-text'><CiMail /> t.farag@aqp-groups.com </div>
            </Col>

            <Col xs = {12} s = {12} md = {4} lg = {4} style={{padding:'3%'}}>
            <div className='subheading'> {props.en?"Local & Global Quality Manager":"Lokaler und globaler Qualitätsmanager"}</div>
            <div className='text'> {props.en?"Mr. ":"Herr Dr. "} Mina Atef</div>
            <div className='small-text'><CiMail /> m.atef@aqp-groups.com </div>
            </Col>


        </Row>
        </div>
        <div className="heading" >
        {props.en?"Our International Members":"Unsere internationalen Mitglieder"}      <FaGlobeAmericas style={{fontSize:'12rem', padding:' 0 1.5%'}}/>

        </div> 

        <Row>
            <Col style={{padding:'3%'}}>
            <div className='subheading'> {props.en?"Sales Director" : "Verkaufsleiter"}</div>
            <div className='text'> {props.en?"Mr. ":"Herr "} Mahmoud Khaled</div>
            <div className='small-text'><CiMail /> m.khaled@aqp-groups.com </div>
            </Col>
            <Col style={{padding:'3%'}}>
            <div className='subheading'> {props.en? "Sales Manager":"Verkaufsleiterin"}</div>
            <div className='text'> {props.en?"Mrs. ":"Frau "} Yasmin Gamal</div>
            <div className='small-text'><CiMail /> y.gamal@aqp-groups.com </div>

            </Col>
        </Row>



        </div>


    );
}

export default Team;