import Carousel from 'react-bootstrap/Carousel'
import {useState, useRef} from 'react'
import img1 from "./../static/first.jpg"
import img2 from "./../static/second.jpg"
import img3 from "./../static/third.jpg"
import Button from 'react-bootstrap/Button'
import '../App.css';



function ControlledCarousel(props) {
    const [index, setIndex] = useState(0);
    const text = props.en? "From nature directly to you" : "Bei AQP von der Natur direkt zu Ihnen!"
    const contactText = props.en? "Contact us" : "Jetzt kontaktieren"


  
    const handleSelect = (selectedIndex, e) => {
      setIndex(selectedIndex);
    };


    const button = <Button onClick={props.scrollHandler} style={{margin:"6% 0 0 0"}}>{contactText}</Button>;



  
    return (
      <div style={{marginBottom:"10%"}}>
      <Carousel activeIndex={index} onSelect={handleSelect} interval={2000}>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img1}
            alt="First slide"
          />
          <Carousel.Caption className='slogan' style={{fontSize: props.en? '2rem':'1.5rem'}}>
            <p>{text}</p>
            {button}


          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img2}
            alt="Second slide"
          />
  
  <Carousel.Caption className='slogan' style={{fontSize: props.en? '2rem':'1.5rem'}}>
            <p>{text}</p>
            {button}


          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={img3}
            alt="Third slide"
          />
          <Carousel.Caption className='slogan' style={{fontSize: props.en? '2rem':'1.5rem'}}>
            <p>{text}</p>
            {button}


          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>      

      </div>
    );
  }
  
export default ControlledCarousel;