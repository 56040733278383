import './App.css';
import React from 'react';
import { useState, useEffect, useRef, forwardRef } from 'react';
import Appbar from './components/Appbar';
import ControlledCarousel from './components/ControlledCarousel';
import Info from './components/Info';
import Services from './components/Services';
import Team from './components/Team';
import ContactForm from './components/Contact';
import Footer from './components/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands, icon } from '@fortawesome/fontawesome-svg-core/import.macro'


class App extends React.Component{
  constructor(props){
    super(props);
    this.contactRef = React.createRef();
    this.state = {en:true};
  }


  render(){
    return(
        <div className="App">
      <Appbar langHandler={(e) => {this.setState({en: e.target.id=='en'})}} scrollHandler={() => {this.contactRef.current?.scrollIntoView({behavior: 'smooth'});}} bold={this.state.en} />
      <ControlledCarousel en={this.state.en} handleLanguage={(e) => {this.setState({en: e.target.id=='en'})}} scrollHandler={() => {this.contactRef.current?.scrollIntoView({behavior: 'smooth'});}}/>
      <Info en={this.state.en}/>
      <Services en={this.state.en}/>
      <Team en={this.state.en}/>
      <ContactForm ref = {this.contactRef} en={this.state.en}/>
      <Footer />
    </div>

    )
  }
}

export default App;
