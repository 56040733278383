import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from "./../static/logo.png";
import React from 'react';
import {BiPhone} from 'react-icons/bi'
import { CiMail } from "react-icons/ci";
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';



function Appbar(props){

  const contactText = props.bold? "Contact us" : "Jetzt kontaktieren"
    return (
        <Navbar bg="dark" variant="dark" >
        <Container fluid>
        <Navbar.Brand href="#logo"><img src={logo} style={{width:"100px", height:"80px", margin:"0 10%"}}/></Navbar.Brand>
          <span className='appbar-text' style={{cursor:'pointer'}} onClick={props.scrollHandler}> {contactText}</span>
          <Nav className="ml-auto" style={{paddingRight:"5%"}}>
          <div onClick={props.langHandler} style={{color:'white', padding:'20%', cursor:"pointer", fontWeight:props.bold?"700":"300"}} id="en">EN </div>
          <div onClick={props.langHandler} style={{color:'white', padding:'20%', cursor:"pointer", fontWeight:props.bold?"300":"700"}} id="de">DE</div>

          </Nav>
          
        </Container>
      </Navbar>
        
    );
}

export default Appbar;
