import '../App.css';
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



function Footer(props){
    return(
        <div className='footer'>
            <br />
        <span style={{margin:'1%'}}>
            {props.en?"Copyright © 2022 AQP-Group":"Copyright © 2022 AQP-Gruppe"}
        </span>
        <span style={{margin:'1%'}}>
            {props.en?"Imprint": "Impressum"}
        </span>
        <br />
        <br />
        <br />

        </div>
    );

}

export default Footer;