import '../App.css';
import React from 'react';
import {HiOutlineOfficeBuilding} from "react-icons/hi";



function Info(props){

    const about = props.en? "About Us" : "Über uns"
    const text = props.en? "We started in Germany in 2018 and have expanded now in Egypt as one of the biggest trading agencies in the  Middle East, exporting best quality fruits and vegetables to Europe for 5 years. Our products are taken care of with many years of experience until they are carefully delivered to our customers." : "Wir haben im Jahr 2018 in Deutschland begonnen und heute in Ägypten als eine der größten Handelsagentur im Nahen Osten, die seit 5 Jahren nach Europe Obst und Gemüse mit bester Qualität exportiert. Unsere Produkte werden mit langjähriger Erfahrung pflegt, bis die zu unseren Kunden sorgfältig geliefert werden. "
    return(
        <div style={{marginBottom:'5%'}}>
        <div className="heading" >
             {about}       <HiOutlineOfficeBuilding  style={{fontSize: '12rem'}} />
        </div>
        <div className="text" style = {{margin:'6% 0'}}>
            {text}
        
        </div>
        </div>
    );
}

export default Info;