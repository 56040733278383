import '../App.css';
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { GiFruitBowl} from "react-icons/gi";
import {FaBriefcase} from "react-icons/fa";


function Services(props){
    const title = props.en? "Our Services" : "Unsere Dienstleistungen"
    const st1 = props.en? "Farm-produced Product Supply" : "Versorgung mit hofeigenen Produkten"
    const st2 = props.en? "Business Development" : "Geschäftsentwicklung"
    const t1 = props.en? "We supply businesses with farm-produced products. We specialize in Fruits and Vegetables supply." : "Wir beliefern Betriebe mit hofeigenen Produkten. Wir sind spezialisiert auf die Lieferung von Obst und Gemüse"
    const t2 = props.en? "We are a group of Importers and Exporters. Apart from supplying clients with farm-produced product supply, we also help clients export their products." : "Wir sind eine Gruppe von Importeuren und Exporteuren. Neben der Versorgung der Kunden mit landwirtschaftlichen Erzeugnissen helfen wir den Kunden auch beim Export ihrer Produkte"


    return(
        <div style={{backgroundColor: '#EEEEEE'}}>
        <div className="heading" >
             {title}
        </div>
        <Row className='row' style={{margin:'3% 0 8% 0'}}>
            <Col xs = {12} s = {12} md = {6} lg = {6}>
            <div className='subheading' style={{padding:'7% 0'}}>{st1}</div>
            <GiFruitBowl style={{fontSize:'12rem'}}/>
            <br />
            <br />
            <br />
            <br />
            <div className='text'>{t1} </div>
            </Col>
            <Col xs = {12} s = {12} md = {6} lg = {6}>
            <div className='subheading' style={{padding:'7% 0'}}>{st2}</div>
            <FaBriefcase style={{fontSize:'12rem'}}/>
            <br />
            <br />
            <br />
            <br />
            <div className='text'>{t2} </div>
            </Col>

        </Row>
        </div>
    );
}

export default Services;