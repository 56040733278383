import '../App.css';
import React, {useState, useRef} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { CCollapse } from '@coreui/react'
import { CiMail } from "react-icons/ci";
import {BsCheckCircle} from 'react-icons/bs'
import { send } from 'emailjs-com';




const ContactForm = React.forwardRef((props, ref) => {
    const [toSend, setToSend] = useState({
        from_name: '',
        to_name: '',
        message: '',
        reply_to: '',
      });
    
    const [visible, setVisible] = useState(false);

    const thanks = props.en? "Thank You!" : "Vielen Dank!"
    const ack = props.en? "We recieved your message and will get back to you as soon as possible." : "Wir haben Ihre Nachricht erhalten und melden uns schnellstmöglich zurück!"

    const handleChange = (e) => {
        setToSend({ ...toSend, [e.target.name]: e.target.value });
      };

      const onSubmit = (e) => {
        e.preventDefault();
        send(
          'service_klcr3ql',
          'template_6c0vy99',
          toSend,
          'W0mrOaae5JoStxWdq'
        )
          .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            setVisible(true);
            ref.current?.scrollIntoView({behavior: 'smooth'});
          })
          .catch((err) => {
            console.log('FAILED...', err);
          });
      };
    return(
        <div style={{backgroundColor:'#EEEEEE', padding:'5%'}} ref={ref}>
            <div className="heading">
             {props.en?"Contact us":"Jetzt kontaktieren"}
            </div>
            <CiMail style={{fontSize:'12rem'}}/>

        <Form style={{padding:'3%', margin:'0% 10% 2% 10%'}} onSubmit={onSubmit}>
            <Row >
                <Col xs = {12} s = {12} md = {6} lg = {6}>
                <Form.Group className="mb-3" controlId="name">
            <Form.Control type="text" placeholder="Name" name = "from_name"value={toSend.from_name} onChange={handleChange} required/>
            </Form.Group>
                </Col>
                <Col xs = {12} s = {12} md = {6} lg = {6}>
                <Form.Group className="mb-3" controlId="phone">
            <Form.Control type="text" placeholder="Phone (Optional)" name = "to_name" value={toSend.to_name} onChange={handleChange} required/>
             </Form.Group>
                </Col>
                <div style={{margin:'0'}}>
                <Form.Group className="mb-3" controlId="email">
            <Form.Control type="email" placeholder="Email" name = "reply_to" value={toSend.reply_to} onChange={handleChange} required />
             </Form.Group>
                </div>
                <div style={{margin:'0'}}>
                <Form.Group className="mb-3" controlId="message">
            <textarea type="text" placeholder="Message" name = "message" value={toSend.message} onChange={handleChange} required style={{height:'10rem', textAlign:'left', overflowY:'auto', padding:'1em', width:'100%', borderColor:"#CCCCCC", borderRadius:'10px'}}/>
             </Form.Group>
                </div>
                <div>
                <Button variant="primary" type="submit" size='lg'>
                {props.en?"Send":"Absenden"}
                </Button>

                </div>
            </Row>
    </Form>
    <CCollapse visible = {visible}>

    <div style={{marginBottom:'5%', backgroundColor: '#c8e6c5'}}>
        <div className="heading" >
             {thanks}      <BsCheckCircle  style={{fontSize: '12rem', margin:'2%'}} />
        </div>
        <div className="text" style = {{margin:'6% 0'}}>
            {ack}
        
        </div>
        </div>
        </CCollapse>
        </div>
    );

})

export default ContactForm;